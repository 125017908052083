<template>
    <div class="view pa24 wordAdmin">
        <!-- hover_pointer -->
        <div class="selectProject flex-a-c mb10">
            <i class="el-icon-help fs12 mr5"></i>
            <span class="fs13">{{ checkedProject.projectName }}</span>
        </div>
        <el-tabs v-model="searchType" :before-leave="beforeLeave">
            <el-tab-pane :label="row.title" :name="row.id" v-for="(row, index) in tabData" :key="index"></el-tab-pane>
        </el-tabs>
        <div class="mt10 h30">
            <div v-if="showProgress">
                <el-progress :text-inside="true" v-if="progressStatus" :stroke-width="26" :percentage="progressNumber"
                    :status="progressStatus"></el-progress>
                <el-progress :text-inside="true" :stroke-width="26" :percentage="progressNumber" v-else></el-progress>
            </div>
        </div>
        <div class="createWord">
            <div class="showModelContent posre">
                <div v-if="contentType === 'content'">
                    <div class="uploadBtn flex-c-c" @click="createWord()" title="生成WORD文档">
                        <i class="el-icon-loading" v-if="createWordLoading"></i>
                        <i class="el-icon-upload" v-else></i>
                    </div>
                    <div class="pdfBtn flex-c-c" @click="createPdf()" title="生成PDF">
                        <i class="el-icon-loading" v-if="createPdfLoading"></i>
                        <span v-else>PDF</span>
                    </div>
                    <div><el-button type="primary" :loading="addWordDataLoading" @click="addWord()">保
                            存</el-button><el-button @click="nextStep">下一步</el-button></div>
                    <div class="bbef0 mt20"></div>
                    <div class="curPo showModelHtml" v-html="modelContent.html"></div>
                    <div class="bbef0 mb20"></div>
                    <div><el-button type="primary" :loading="addWordDataLoading" @click="addWord()">保
                            存</el-button><el-button @click="nextStep">下一步</el-button></div>
                </div>
                <div class="flex-c-c showModelStateContent" v-if="contentType === 'null'">
                    <div class="textc">
                        <div class="el-icon-folder-delete eIcon"></div>
                        <div>该类型没有模板，请添加该类型模板 <el-button size="small" type="text" @click="toModel()">添加模板</el-button></div>
                    </div>
                </div>
                <div class="flex-c-c showModelStateContent" v-if="contentType === 'select'">
                    <div class="textc">
                        <div class="el-icon-folder-add eIcon"></div>
                        <div>当前未选中项目模板<el-button size="small" type="text" @click="showModelFun()">请选择模板</el-button></div>
                    </div>
                </div>
                <div class="flex-c-c showModelStateContent" v-if="contentType === 'info'">
                    <div class="textc">
                        <div class="el-icon-loading eIcon"></div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="80%" center>
            <div class="d-flex mt10 mb10">
                <div>
                    <el-input class="w400 mr10" placeholder="请输入模板名称" v-model="modelSearchData.name" />
                    <el-button type="primary" @click="modelSearchFun">搜索</el-button>
                </div>
            </div>
            <el-table v-loading="modelLoading" class="tableRadio" ref="multipleTable" :data="modelTableData" border lay
                tooltip-effect="dark" @current-change="selectModel" :highlight-current-row="true">
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="name" align="center" label="模板名称" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="userName" align="center" label="提交用户" show-overflow-tooltip width="120" />
                <el-table-column prop="fileUrl" align="center" label="类型" show-overflow-tooltip width="120">
                    <template slot-scope="scope">
                        {{ typeData[scope.row.modelType] }}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" align="center" label="上传时间" show-overflow-tooltip width="200" />
            </el-table>
            <div style="display: flex; justify-content: flex-end; margin-top: 16px" v-if="modelTableData.length !== 0">
                <el-pagination background @size-change="modelSizeChange" @current-change="modelCurrentChange"
                    :current-page="modelCurrentPage" :page-sizes="[10, 15, 20]"
                    layout="total, sizes, prev, pager, next, jumper" class="" :total="modelTotal" :pager-count="5" />
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
            </span>
        </el-dialog>
        <gpt-model ref="gptModel" @clickAnswer="clickAnswer" @caleChange="caleChange" :gptTitle="gptTitle"
            :commonUseMessage="commonUseMessage"></gpt-model>
    </div>
</template>

<script>

import { getWordModelList, writeAndDownload, writeAndDownloadFile } from "@/api/projectAdmin";
import gptModel from "@/components/common/gptModel";
let base64 = require('js-base64').Base64;
export default {
    name: "createaWord",
    components: {
        gptModel
    },
    data() {
        return {
            //gpt配置
            gptTitle: "",
            commonUseMessage: [
                '如何增加申报项目成功率',
                '申报项目该准备什么材料',
                '项目申报成功后资金要多久才到账',
            ],
            topic: {},
            //列表配置
            userInfo: this.$store.state.loginRoot.userInfo,
            //导入模板配置
            uploadModel: {},
            modelContent: {
                html: "",
                list: [],
                normal: {}
            },
            tabData: [
                { id: '1', title: "立项报告" },
                { id: '2', title: "可行性报告" },
                { id: '3', title: "立项决议" },
                { id: '4', title: "测试报告" },
                { id: '5', title: "结题报告" }
            ],
            typeData: {
                0: "其他", 1: "立项报告", 2: "可行性报告", 3: "立项决议", 4: "测试报告", 5: "结题报告"
            },
            searchType: "1",
            modelType: "",

            //研发项目配置
            contentType: "info",//info null select content
            checkedProject: JSON.parse(sessionStorage.getItem('checkedProject')),//项目数据
            checkedModel: {},//选中的模板数据
            progressNumber: 0,
            progressStatus: "",
            showProgress: false,
            addWordDataLoading: false,
            checkedCompany: {},
            timeFun: null,
            timeFunB: null,

            //选择模板配置
            //列表配置
            modelCurrentPage: 1, //当前页
            modelPageSize: 10, //显示条数
            modelLoading: false, //表格加载
            modelTotal: 0, //总条数
            showModel: false,
            modelTableData: [],
            modelSearchData: { name: "" },
            //模态框配置
            modelTitle: "点击选择项目模板",
            initModelData: {},
            createWordLoading: false,
            createPdfLoading: false,
        };
    },
    mounted() {
        this.getModelList();
        this.createeWordInfoClick();//添加编辑事件
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        this.checkedCompany = checkedCompany;
    },
    methods: {
        /**@method 跳转添加模板 */
        toModel() {
            this.$router.push('modelAdmin')
        },
        /**@method 缩放回调 */
        caleChange(caleType) {
            if (caleType == "bigRight") {
                $(".createWord").css("margin-left", "0px")
                $(".createWord").css("width", "calc(100% - 500px)")
            } else if (caleType == "bigLeft") {
                $(".createWord").css("margin-left", "500px")
                $(".createWord").css("width", "calc(100% - 500px)")
            } else if (caleType == 'centre') {
                $(".createWord").css("margin-left", "0px")
                $(".createWord").css("width", "100%")
            } else if (caleType == 'small') {
                $(".createWord").css("margin-left", "0px")
                $(".createWord").css("width", "100%")
            }
        },
        /**@method GPT点击回答回调 */
        clickAnswer(str) {
            if (!this.topic.text) {
                this.copyFun(str);
                return;
            }
            // $(`.${this.topic.classText}[data-title="${this.topic.text}"]`).text(str);
            let $wordContent = $(`.${this.topic.classText}[data-title="${this.topic.text}"]`)
            $wordContent.html(`<textarea class="wordTextarea">${str.replace(/<br>+/ig, "\n")}</textarea>`);
            $wordContent.children("textarea").focus();
            this.$nextTick(function () {
                $(".wordTextarea").css("height", $(".wordTextarea")[0].scrollHeight + 5 + "px");
            })
        },
        /**@method 选中模板 */
        selectModel(data) {
            this.contentType = 'content'
            this.checkedModel = data;
            this.generatedContent();
            this.hideModelFun();
        },
        beforeLeave(val) {
            if (JSON.stringify(this.checkedModel) != "{}") {
                return this.$confirm('此操作将清空填入数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.searchType = val;
                    this.selectType();
                })
            } else {
                this.searchType = val;
                this.selectType();
            }

        },
        /**@method 切换模板类型 */
        selectType() {
            this.modelCurrentPage = 1;
            this.modelSearchData.name = "";
            this.checkedModel = {};
            this.modelContent = {
                html: "",
                list: [],
                normal: {}
            };
            this.getModelList();
        },
        /**@method 生成内容 */
        generatedContent() {
            let modelFormat = {};
            if (JSON.stringify(this.checkedCompany) != "{}") {
                let companyWords = {
                    companyName: "公司名",
                    businessAddress: "办公地址",
                    introduce: "公司介绍",
                    legalName: "法人代表",
                    registerAddress: "注册地址",
                    startTime: "成立时间",
                }
                for (let key in companyWords) {
                    modelFormat[companyWords[key]] = this.checkedCompany[key] ? this.checkedCompany[key] : "";
                }
            }
            if (JSON.stringify(this.checkedProject) != "{}") {
                let checkedProject = this.checkedProject;
                checkedProject.startEndDay = checkedProject[startDay] + "~" + checkedProject[endDay];
                let normalKeywords = {//项目数据赋值参数
                    projectNo: "序号",
                    projectName: "项目名",
                    money: "预算",
                    startEndDay: "研发日期",
                    startDay: "开始时间",
                    endDay: "结束时间",
                    headName: "负责人",
                    proposerName: "申请人",
                    testName: "测试人",
                    projectDay: "立项日期",
                    moneyFrom: "经费来源",
                    departmentName: "所属部门",
                    projectFrom: "项目来源",
                }
                for (let key in normalKeywords) {
                    modelFormat[normalKeywords[key]] = checkedProject[key] ? checkedProject[key] : "";
                }
                let listColKeywords = {
                    userName: "姓名",
                    department: "部门",
                    work: "岗位",
                    education: "学历",
                    university: "毕业院校",
                    major: "专业",
                    job: "职称",
                    inTime: "入职时间",
                    outTime: "离职时间",
                    remark: "备注",
                };
                let projectUserList = [];
                if (checkedProject.projectUserList) {
                    for (let row of checkedProject.projectUserList) {
                        let obj = {};
                        for (let key in listColKeywords) {
                            obj['项目组成员_' + listColKeywords[key]] = row[key] ? row[key] : "";
                        }
                        projectUserList.push(obj);
                    }
                }
                modelFormat['项目组成员'] = projectUserList;

            }
            if (JSON.stringify(this.checkedModel) != "{}") {
                this.showContent = true;
                let htmlContent = JSON.parse(this.checkedModel.content);
                this.createWordHtml(htmlContent, modelFormat);
            }
        },
        createWordHtml(modelData, value) {
           //console.log(modelData);
           //console.log(value);
            let modelHtml = modelData.html;
            modelHtml = modelHtml.replace(/<table/ig, '<div class="modelTable"><table width="100%"')
            modelHtml = modelHtml.replace(/<\/table>/ig, '</table></div>')
            // this.$set(this.modelContent,'html',modelHtml);
            // this.$set(this.modelContent,'normal',modelData.normal ? modelData.normal : []);
            // this.$set(this.modelContent,'list',modelData.list ? modelData.list : []);
            this.modelContent = {
                html: modelHtml,
                normal: modelData.normal ? modelData.normal : [],
                list: modelData.list ? modelData.list : []
            };
            this.$nextTick(() => {
                for (let key in value) {
                    let row = value[key];
                    /**判断数据类型，
                     * 为列表是时候赋值列表数据
                     * 为内容的时候赋值内容
                     */
                    if (typeof row === 'object') {
                        let tableTrView = null;
                        for (let childItem of row) {//循环列表
                            let tableTrHtml = "";
                            for (let childKey in childItem) {//循环列名
                                if (!tableTrView) {
                                    tableTrView = $(`.showModelContent .isList[name=${childKey}]`).parents('tr');
                                    if (tableTrView.length > 0) {//删除多余的列表行
                                        for (let i = 0; i < tableTrView.length; i++) {
                                            if (i != 0) {
                                                tableTrView[i].remove();
                                            }
                                        }
                                    }
                                }
                                // let tableTextarea = tableTrView.find(`textarea.isList[name=${childKey}]`)
                                // if (tableTextarea && tableTextarea.length > 0) {//文本框赋值
                                //     tableTextarea.html(childItem[childKey]);
                                // }
                                let textareaLists = tableTrView.find(`.isList`)//文本框赋值
                                for (let i = 0; i < textareaLists.length; i++) {
                                    let $html = $(textareaLists[i]);
                                    if ($html.attr("name").indexOf(childKey) != -1) {
                                        $html.html(childItem[childKey] ? childItem[childKey].replace(/\n+/ig, "<br>") : childKey);
                                    }
                                }
                            }
                            tableTrHtml = tableTrView.html();
                            tableTrView.before("<tr>" + tableTrHtml + "</tr>");//插入列表行
                        }
                        if (tableTrView) {
                            tableTrView.remove();
                        }
                    } else {
                        let textareaInputs = $(`.showModelContent .isInput`);
                        for (let i = 0; i < textareaInputs.length; i++) {
                            let $html = $(textareaInputs[i]);
                            if ($html.attr("name").indexOf(key) != -1) {
                                if (row) {
                                    $html.html(String(row).replace(/\n+/ig, "<br>"));
                                    $html.removeClass("wordButton");
                                }
                            }
                        }
                    }
                }
                let $textAreaIsList = $(".showModelContent .isList");
                if ($textAreaIsList && $textAreaIsList.length > 0) {//添加 添加/删除按钮
                    let buttonHtml = `<td>
                        <div class="flex-a-c">
                            <div class="flex-a-c h30 ml10" style="width:85px;border:1px solid #51CDCB;padding:0 5px;position:absolute;border-radius:20px;margin-left:10px;background:#fff;">
                                <button type="button" class="el-button--text rowTop"><span class="el-icon-top"></span></button>
                                <button type="button" class="el-button--text rowBottom"><span class="el-icon-bottom"></span></button>
                                <button type="button" class="el-button--text addRow"><span class="el-icon-plus"></span></button>
                                <button type="button" class="el-button--text el-button--small delRow" style="color: rgb(245, 108, 108);"><span class="el-icon-delete"></span></button>    
                            </div>    
                        </div>
                    </td>`;
                    let $trView = $textAreaIsList.parents("tr");
                    let $addRow = $trView.find(".addRow");
                    if (!$addRow || $addRow.length == 0) {
                        $trView.append(buttonHtml);
                    }
                }
            })
        },
        /**@method 模板编辑事件 */
        createeWordInfoClick() {
            let that = this;
            $(".wordAdmin").on("click", ".isInput", function () {
                if ($(this).children("textarea").length == 0) {
                    let text = $(this).data("title");
                    that.topic.text = text;
                    that.gptTitle = '题目： ' + text;
                    that.topic.classText = "isInput";
                    // that.message = text;
                }
            })
            $(".wordAdmin").on("dblclick", ".isInput", function () {
                if ($(this).children("textarea").length == 0) {
                    $(this).html(`<textarea class="wordTextarea">${$(this).html().replace(/<br>+/ig, "\n")}</textarea>`);
                    // $(this).children("textarea").focus()
                    $(this).children("textarea").select();
                    that.$nextTick(function () {
                        let h = $(".wordTextarea")[0].scrollHeight;
                        $(".wordTextarea").css("height", (h + 5) + "px");
                        $(".wordTextarea").css("width", $(".wordTextarea").parent().scrollWidth);
                    })
                }
            })
            $(".wordAdmin").on("blur", ".isInput textarea.wordTextarea", function () {
                $(this).parent().html($(this).val() ? $(this).val().replace(/\n+/ig, "<br>") : $(this).parent().attr("name"));
            })
            $(".wordAdmin").on("click", ".modelTable .isList", function () {
                if ($(this).children("textarea").length == 0) {
                    let text = $(this).data("title");
                    that.topic.text = text;
                    that.gptTitle = '题目： ' + text;
                    that.topic.classText = "isList";
                    // that.message = text;
                }
            })
            $(".wordAdmin").on("dblclick", ".modelTable .isList", function () {
                if ($(this).children("textarea").length == 0) {
                    $(this).html(`<textarea class="wordTextarea">${$(this).html().replace(/<br>+/ig, "\n")}</textarea>`);
                    $(this).children("textarea").select();
                    that.$nextTick(function () {
                        let h = $(".wordTextarea")[0].scrollHeight;
                        $(".wordTextarea").css("height", (h + 5) + "px");
                        // $(".wordTextarea").css("width",$(this)[0].scrollWidth);
                    })
                }
            })
            $(".wordAdmin").on("blur", ".modelTable textarea.wordTextarea", function () {
                $(this).parent().html($(this).val() ? $(this).val().replace(/\n+/ig, "<br>") : $(this).parent().attr("name"));
            })
            $(".wordAdmin").on("click", ".rowTop", function () {
                let myHtml = $(this).parents("tr").html();
                let bottomHtml = $(this).parents("tr").prev().html();
                $(this).parents("tr").prev().html(myHtml);
                $(this).parents("tr").html(bottomHtml);
            })
            $(".wordAdmin").on("click", ".rowBottom", function () {
                let myHtml = $(this).parents("tr").html();
                let bottomHtml = $(this).parents("tr").next().html();
                $(this).parents("tr").next().html(myHtml);
                $(this).parents("tr").html(bottomHtml);
            })
            $(".wordAdmin").on("click", ".addRow", function () {
                let $tr = $(this).parents("tr");
                $tr.after("<tr>" + $tr.html() + "</tr>");
                let td = $tr.next().find(".isList")
                for (let i = 0; i < td.length; i++) {
                    td[i].innerHTML = td[i].getAttribute("data-title");
                }
            })
            $(".wordAdmin").on("click", ".delRow", function () {
               //console.log($(this).parents("tr").children("td"));
                $(this).parents("tr").remove();
            })
        },
        /**@method 获取提交数据 */
        getParams() {
            let params = {};
            let normal = this.modelContent.normal
            let list = this.modelContent.list
            for (let name of normal) {
                if ($(`.isInput[name=${name}]>textarea`).length > 0) {
                    let htmlVal = $(`.isInput[name=${name}]>textarea`).val()
                    params[name] = htmlVal ? htmlVal.replace(/<br>+/ig, "\n") : "";
                } else {
                    let htmlContent = $(`.isInput[name=${name}]`).html()
                    params[name] = htmlContent ? htmlContent.replace(/<br>+/ig, "\n") : "";
                }
            }
            // list:[ { 项目组成员: ["姓名"] } ]
            for (let row of list) {// { 项目组成员: ["姓名"] } 
                for (let tableName in row) {// 项目组成员
                    if (!params[tableName]) {
                        params[tableName] = [];
                    }
                    for (let liName of row[tableName]) {// ["姓名"]
                        let $listTextarea = $(`.isList[name=${tableName}_${liName}]`)
                        let content = "";
                        for (let i = 0; i < $listTextarea.length; i++) {
                            content = $listTextarea[i].innerHTML;
                            if (!params[tableName][i]) {
                                params[tableName][i] = {};
                            }
                            params[tableName][i][tableName + "_" + liName] = content.replace(/<br>+/ig, "\n");
                        }

                    }

                }

            }
            this.param = params;
        },
        getObjectURL(file) {
            var url = null;
            if (window.createObjectURL != undefined) {
                url = window.createObjectURL(file);
            } else if (window.URL != undefined) {
                url = window.URL.createObjectURL(file);
            } else if (window.webkitURL != undefined) {
                url = window.webkitURL.createObjectURL(file);
            }
            return url;
        },
        /**@method 生成文档 */
        addWord() {
            try {
                if (!this.addWordDataLoading) {
                    this.addWordDataLoading = true;
                    this.showProgressFun("start");
                    this.getParams();
                    let params = {
                        param: base64.encode(JSON.stringify(this.param)),
                        modelId: this.checkedModel.modelId,
                        pdId: this.checkedProject.pdId,
                        userId: this.userInfo.userId,
                        pcId: this.checkedCompany.pcId,
                        fileName: this.checkedProjectName
                    };
                    if (this.formType == 'update') {
                        params.dataId = this.updateId;
                    }

                    writeAndDownload(params, {}).then(res => {
                        if (res.code === 200) {
                            this.$confirm('保存完成，是否要现在下载文件?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                // window.open(res.data);
                                let link = document.createElement('a');
                                link.href = res.data;
                                link.click();
                            })
                            this.addWordDataLoading = false;
                            this.showProgressFun("end")
                        } else {
                            this.$message.error(res.message)
                            this.showProgressFun("error")
                        }
                    }).catch(err => {
                        this.showProgressFun("error")
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            } catch (err) {
                this.addWordDataLoading = false;
            }
        },
        /**@method　生成word */
        createWord() {
            this.$message.info("待开发");
            return;
            this.showProgressFun("start");
            if (!this.createWordLoading) {
                this.createWordLoading = true;

                this.getParams();
                let params = {
                    param: base64.encode(JSON.stringify(this.param)),
                    modelId: this.checkedModel.modelId,
                    pdId: this.checkedProject.pdId,
                    userId: this.userInfo.userId,
                    pcId: this.checkedCompany.pcId
                };

                writeAndDownloadFile(params, this.checkedProjectName).then(res => {
                    if (res.code === 200) {
                        this.createWordLoading = false;
                        this.showProgressFun("end")
                    } else {
                        this.$message.error(res.message)
                        this.showProgressFun("error")
                    }
                }).catch(err => {
                    this.showProgressFun("error")
                    if (err.code === 201) {
                        this.$message.error(err.message)
                    }
                })
            }
        },
        createPdf() {
            this.$message.info("待开发");
        },
        showProgressFun(status) {
            if (this.timeFun) {
                clearTimeout(this.timeFun)
                this.timeFun = null;
            }
            if (status === 'start') {
                this.progressStatus = "";
                this.showProgress = true;
                this.progressNumber = 0;
                this.timeFun = setTimeout(() => {
                    this.progressNumber = 95;
                }, 300)
            } else if (status == 'end') {
                this.progressNumber = 100;
                setTimeout(() => {
                    this.showProgress = false;
                }, 2000)
            } else if (status == 'error') {
                this.progressNumber = 95;
                this.progressStatus = "exception"
                setTimeout(() => {
                    this.showProgress = false;
                }, 2000)
            }
        },
        showModelFun() {
            this.showModel = true;
        },
        hideModelFun() {
            this.showModel = false;
        },
        /**@method 搜索 */
        modelSearchFun() {
            this.modelCurrentPage = 1;
            this.getModelList();
        },
        /**@method 下一步 */
        nextStep() {
            this.modelContent = {
                html: "",
                list: [],
                normal: {}
            };
            this.showProgressFun("start");
            if (this.timeFunB) {
                clearTimeout(this.timeFunB);
            }
            this.timeFunB = setTimeout(() => {
                this.showProgressFun("end");
                this.selectModel(this.checkedModel);
                clearTimeout(this.timeFunB);
                this.timeFunB = null;
            }, 300)
        },
        /**@method 获取列表 */
        async getModelList() {
            this.contentType = "info"
            this.showProgressFun("start");
            let params = {
                pageSize: this.modelPageSize,
                pageNum: this.modelCurrentPage,
                name: this.modelSearchData.name,
                modelType: this.searchType
            };
            try {
                this.modelLoading = true;

                let result = await getWordModelList(params)
                const { data } = result;
                this.modelLoading = false;
                this.modelTableData = data ? data.pageInfo.list : [];
                this.total = data ? data.pageInfo.total : 0;
                this.showProgressFun("end");

                //判断
                if (this.modelCurrentPage == 1 && this.modelTableData.length == 1) {
                    this.selectModel(this.modelTableData[0]);
                    this.contentType = "content"
                } else if (this.modelCurrentPage == 1 && this.modelTableData.length > 1) {
                    this.showModelFun();
                    this.contentType = "select"
                } else if (this.modelCurrentPage == 1 && this.modelTableData.length == 0) {
                    this.contentType = "null"
                }
            } catch (error) {
               //console.log(error);
                this.contentType = "null"
                this.modelLoading = false;
                this.modelTableData = [];
                this.showProgressFun("error");
            }
        },
        /**@method 切换行 */
        modelSizeChange(val) {
            this.modelPageSize = val;
            this.getModelList();
        },
        /**@method 切换下一页 */
        modelCurrentChange(val) {
            this.modelCurrentPage = val;
            this.getModelList();
        }
    },
};
</script>
<style>
.wordTextarea {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #DCDFE6;
    text-align: justify;
}

.modelTable .wordTextarea {
    width: 100%;
}

.uploadWordImage {
    height: 36px;
}
</style>
<style lang="scss" scoped>
/deep/ .el-upload--text {
    width: 90px;
    height: 36px;
    border: 0px;
}

.showModelContent {
    width: 900px;
    margin: auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    padding: 20px;
    margin-right: 100px;
}

.selectProject {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
}

.eupload.el-icon-loading {
    font-size: 67px;
    color: #c0c4cc;
    margin: 40px 0 16px;
    line-height: 50px;
}

.uploadBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #3a78ef;
    color: #fff;
    font-size: 40px;
    position: fixed;
    right: 60px;
    top: 130px;
    cursor: pointer;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.50);
}

.pdfBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #F56C6C;
    color: #fff;
    font-size: 20px;
    position: fixed;
    right: 60px;
    top: 190px;
    cursor: pointer;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.50);
}

.showModelContent {
    margin: auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

    .showModelHtml {
        //height: 630px;
        max-width: 842px;
        //overflow-x: scroll;
    }

    .showModelStateContent {
        height: 630px;
        max-width: 842px;
        margin-top: 10px;
        padding: 20px;

        .eIcon {
            font-size: 65px;
        }

        a {
            cursor: pointer;
            color: #51CDCB;
            margin-left: 5px;
        }
    }

}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>